// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	//base_url: 'https://back.qa.wisecheckin.com',
	base_url: 'https://back.qa.wisecheckin.com/api',

	//cdn_url: 'https://wfpublicqa.s3.sa-east-1.amazonaws.com',
	web_url: 'https://visitors.qa.wisecheckin.com',
	cdn_url: 'https://wfpublicqa.s3.sa-east-1.amazonaws.com',
	recaptcha: {
		site_key: '6LdeRrgjAAAAADTDr21D-2GAZmNJ4bA0c9OZM5V6',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
