import { Routes } from '@angular/router';

export const appRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'registro',
	},
	{
		path: 'registro',
		loadChildren: () => import('@pages/register/routes'),
	},
	{
		path: 'pagina-no-encontrada',
		loadChildren: () => import('@pages/not-found/routes'),
	},
	{ path: '**', redirectTo: 'pagina-no-encontrada' },
];
