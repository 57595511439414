import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, PreloadAllModules, withPreloading } from '@angular/router';
import { appRoutes } from './app/app.routing';
import { AppComponent } from './app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { importProvidersFrom, LOCALE_ID } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '@env/environment';
registerLocaleData(localeEs, 'ESP');
bootstrapApplication(AppComponent, {
	providers: [
		provideRouter(appRoutes, withPreloading(PreloadAllModules)),
		importProvidersFrom(BrowserAnimationsModule),
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
		{ provide: LOCALE_ID, useValue: 'ESP' },
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.site_key },
	],
}).catch((e) => console.log(e));
